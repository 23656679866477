@import url(https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.9/css/weather-icons.min.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --ppp-dur: 0.3s;
  --ppp-dark: #121F2B;
  --ppp-black: black;
  --ppp-white: white;
  --ppp-grey: #dcdcdc;
  --ppp-transparent: rgba(255, 255, 255, 0);
}

body {
  background-color: var(--ppp-dark);
  height: 100vh;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.bar{
  margin-top: 0px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width:300px;
  border-radius:30px;
  border:1px solid var(--ppp-grey);
  background-color: var(--ppp-white);
  transition: var(--ppp-dur);
}
.bar:hover{
  box-shadow: 1px 1px 8px 1px var(--ppp-grey);
}
.bar:focus-within{
  box-shadow: 1px 1px 8px 1px var(--ppp-grey);
  outline:none;
}
.searchbar{
  text-align: center;
  background-color: var(--ppp-white);
  height:45px;
  border:none;
  width:90%;
  font-size:16px;
  outline: none;
}

.ppp-grid {
  padding: 2rem;
}
.ppp-grid > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}
.ppp-grid > ul > li {
  padding-top: 10px;
  padding-bottom: 10px;
  list-style: none;
  background-color: var(--ppp-transparent);
  color: var(--ppp-white);
  border: 1px solid var(--ppp-white);
  border-radius: .5rem;
  transition: var(--ppp-dur);
}
.ppp-grid > ul > li:hover {
  background-color: var(--ppp-black);
}
.ppp-grid > ul > li > a {
  color: var(--ppp-white);
}
.ppp-grid > ul > li > p.ppp-large {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 200%;
  height: 50px;
  line-height: 50px;
}
.ppp-grid > ul > li > p > img {
  margin-top: auto;
  margin-bottom: auto;
  height: 50px;
  line-height: 50px;
  vertical-align: baseline;
}
.ppp-grid > ul > li > p.ppp-regular {
  /* margin-top: none;
  margin-bottom: auto; */
  font-size: 100%;
  height: 50px;
  line-height: 50px;
}

.ppp-card {
  background-color: var(--ppp-white);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}